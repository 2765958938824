import React from 'react';
import '../styles/Contact.css';

const Contact = () => (
  <div className="contact">
    <h1>Contact Me</h1>
    <p>Form or details to contact you.</p>
  </div>
);

export default Contact;
