import React from 'react';
import '../styles/Home.css';

const Home = () => (
  <div className="home">
    <h1>Welcome to My Portfolio</h1>
    <p>This is a brief introduction about myself and my work.</p>
  </div>
);

export default Home;
