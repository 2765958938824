import React from 'react';
import '../styles/About.css';

const About = () => (
  <div className="about">
    <h1>About Me</h1>
    <p>Information about your background, experience, and skills.</p>
  </div>
);

export default About;
