import React from 'react';
import '../styles/Projects.css';

const Projects = () => (
  <div className="projects">
    <h1>My Projects</h1>
    <p>Details of the projects you have worked on.</p>
  </div>
);

export default Projects;
